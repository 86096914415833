<template>
    <svg xmlns="http://www.w3.org/2000/svg" :width="width" :height="height" viewBox="0 0 16 17" fill="none">
    <path d="M3.2 0.416672V2.01667H14.4V13.2167H16V2.01667C16 1.13267 15.284 0.416672 14.4 0.416672H3.2ZM1.6 3.61667C0.716 3.61667 0 4.33267 0 5.21667V14.8167C0 15.7007 0.716 16.4167 1.6 16.4167H11.2C12.084 16.4167 12.8 15.7007 12.8 14.8167V5.21667C12.8 4.33267 12.084 3.61667 11.2 3.61667H1.6ZM5.6 6.81667H7.2V9.21667H9.6V10.8167H7.2V13.2167H5.6V10.8167H3.2V9.21667H5.6V6.81667Z"
    :fill="fill"/>
  </svg>
</template>

<script>
export default {
  props: {
    width: {
      type: [Number, String],
      default: 16
    },
    height: {
      type: [Number, String],
      default: 17
    },
    fill: {
      type: String,
      default: '#9CA6BA'
    }
  }
}
</script>
